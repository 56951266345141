.slick-dots-script {
  position: absolute;
  bottom: 10px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots-script li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 4px;
  padding: 0;
  cursor: pointer;
}

.app-slick-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #333;
}

.slick-dots-script li.slick-active .app-slick-dot {
  background-color: #fff;
}

.slick-next {
  right: 15px;
  z-index: 10;
}

.slick-prev {
  left: 15px;
  z-index: 10;
}
